import React from 'react';
import logo1 from '../img/s1.jpeg'
import logo2 from '../img/s2.jpeg'

const BlogPost = ({ title, date, excerpt, imageAlt, imageSrc, readMoreLink }) => {
    return (
      <article className="blog__post" >
        <img src={imageSrc} alt={imageAlt} />
        <h3 className="post__title">{title}</h3>
        <p className="post__date">{date}</p>
        <p className="post__excerpt">{excerpt}</p>
        <a href={readMoreLink} className="post__read-more">
          Leer Más
        </a>
      </article>
    );
  };


const BlogSidebar = () => {
  return (
    <section className="blog__sidebar">
      <h2 className="sidebar__title">Categorías</h2>
      <ul className="sidebar__categories">
        <li><a href="#">Salud General</a></li>
        <li><a href="#">Tecnología Médica</a></li>
        <li><a href="#">Consejos de Bienestar</a></li>
        
      </ul>

      <h2 className="sidebar__title">Artículos Populares</h2>
      <ul className="sidebar__popular-posts">
        <li><a href="#">Cómo mejorar tu salud mental</a></li>
        <li><a href="#">Avances en la telemedicina</a></li>
        
      </ul>
    </section>
  );
};


const Blog = () => {
  const posts = [
    {
      title: 'La Importancia de Mediconect: Cómo Puede Salvar Vidas',
      date: 'Publicado el 15 de Febrero de 2024',
      excerpt: 'La tecnología médica ha avanzado significativamente en las últimas décadas...',
      imageSrc: logo1,
      imageAlt: 'Imagen del artículo 1',
      readMoreLink: '#',
    },
    {
      title: 'Cómo la Telemedicina Está Cambiando la Forma en que Accedemos a la Atención Médica',
      date: 'Publicado el 20 de Febrero de 2024',
      excerpt: 'La telemedicina ha experimentado un crecimiento significativo en los últimos años...',
      imageSrc: logo2,
      imageAlt: 'Imagen del artículo 2',
      readMoreLink: '#',
    },
    // Add more posts as needed
  ];

  return (
    <main className="blog__main container">
      <section className="blog__section ">
        <h2 className="section__title">Últimas Publicaciones</h2>
        
        {posts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </section>

      <BlogSidebar />
    </main>
  );
};

export default Blog;
