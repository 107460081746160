import React from 'react';
import img1 from "../img/alejo.jpg"
import img2 from "../img/juan.jpg"
import logo from "../img/estetoscopio.png"

const About = () => {
  return (
    <main>
      <section className="about__section container">
      <h2 className="section__title"><p >Conoce a &nbsp;</p>  <p>MediC<img src={logo} alt="Icono"/>nnect</p></h2>
        <p className="section__paragraph">Mediconect es una plataforma innovadora que busca mejorar la experiencia de pacientes y profesionales de la salud en la gestión médica. Nuestra misión es proporcionar herramientas tecnológicas avanzadas para facilitar el acceso a la información médica, promover un cuidado personalizado y mejorar la comunicación entre pacientes y profesionales de la salud.</p>
      </section>

      <section className="team__section container">
        <h2 className="section__title">Nuestro Equipo</h2>
        <p className="section__paragraph">Detrás de Mediconect, hay un equipo apasionado de profesionales comprometidos con la mejora continua de la atención médica. Nos esforzamos por combinar la experiencia en medicina y tecnología para ofrecer soluciones que marquen la diferencia en la vida de las personas.</p>

        <div className="team__members">
          <div className="team__member">
            <img src={img1} alt="Luis Alejandro Monroy - Desarrollador y Encargado del Proyecto" className="profile-image"/>
            <h3 className="member__name">Luis Alejandro Monroy</h3>
            <p className="member__position">Desarrollador y Encargado del Proyecto</p>
          </div>

          <div className="team__member">
            <img src={img2} alt="Juan Carlos Peña - Desarrollador y Encargado del Proyecto" className="profile-image"/>
            <h3 className="member__name">Juan Carlos Peña</h3>
            <p className="member__position">Desarrollador y Encargado del Proyecto</p>
          </div>
        </div>
      </section>

      <section className="mission__section container">
        <h2 className="section__title">Nuestra Misión</h2>
        <p className="section__paragraph">En Mediconect, nos esforzamos por revolucionar la atención médica a través de la tecnología. Creemos en un enfoque centrado en el paciente, donde la información es accesible, la comunicación es fluida y la atención es personalizada. Nuestra misión es empoderar a pacientes y profesionales de la salud para lograr un cuidado médico óptimo y eficiente.</p>
      </section>
    </main>
  );
};

export default About;
