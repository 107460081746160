import React, { useState } from 'react';
import '../css/App.css';
import '../css/acerca.css';
import '../css/App.css';
import '../css/blog.css';
import '../css/cont.css';
import '../css/estilos.css';
import '../css/index.css';
import '../css/normalize.css';
import '../css/styles.css';
import Header from './Header';
import Main from './main';
import Footer from './footer';
import Blog from './blog';
import Contact from './Contact';
import About from './about';


import contimg from '../img/pexels-nataliya-vaitkevich-6941105.jpg'
import abouimg from '../img/pexels-andrea-piacquadio-3769151.jpg'
import homeimg from '../img/pexels-castorly-stock-4114781.jpg'
import blogimg from '../img/about.jpg'

function App() {
  const [ruta, setRuta] = useState("home")
  const [burger, setBurger] = useState(true)

  return (
    <div className="App">
      <Header setRuta={setRuta} show={burger} setshow={setBurger} fondo={ruta == "contact" ? contimg : ruta == "about" ? abouimg : ruta == "home" ? homeimg : blogimg}/>

      {ruta == "home" && <Main/> }
      {ruta == "blog" && <Blog/> }
      {ruta == "about" && <About/> }
      {ruta == "contact" && <Contact/> }

      <i className='menu-bar' onClick={()=> setBurger(!burger)}/>

      <Footer setRuta={setRuta}/>

    </div>
  );
}

export default App;
