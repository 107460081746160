import React, { useState } from 'react';
import logo1 from '../img/pexels-rdne-stock-project-6129035.jpg'
import img1 from '../images/leftarrow.svg'
import img2 from '../images/rightarrow.svg'
import img3 from '../images/arrow.svg'

import pimg1 from '../images/face.jpg'
import pimg2 from '../images/face2.jpg'
import pimg3 from '../images/face3.jpg'
import pimg4 from '../images/face4.jpg'

const Main = () => {

    const [showAnswer, setShowAnswer] = useState(false);

    const [showicon, setShow] = useState("0");

    const toggleAnswer = () => {
        setShowAnswer(!showAnswer);
    };

    const [currentTestimony, setCurrentTestimony] = useState(1);

    const handlePrevTestimony = () => {
        setCurrentTestimony((prev) => (prev > 1 ? prev - 1 : 4));
    };

    const handleNextTestimony = () => {
        setCurrentTestimony((prev) => (prev < 4 ? prev + 1 : 1));
    };


    return (
        <main>
            <section className="container about">
                <h2 className="subtitle">Descubre las habilidades clave para el Control Médico en Mediconect</h2>
                <p className="about__paragraph">En el ámbito del control médico en plataformas como Mediconect, es fundamental contar con un conjunto específico de habilidades que garanticen la eficiencia, precisión y calidad en la gestión de la atención médica. Estas habilidades no solo abarcan aspectos técnicos, sino también competencias interpersonales y de gestión que son esenciales para el éxito en este campo.</p>

                <div className="about__main">
                    <article className="about__icons">
                        <span className="about__icon" style={{ color: "#0facbf" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-earmark-medical-fill" viewBox="0 0 16 16">
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m-3 2v.634l.549-.317a.5.5 0 1 1 .5.866L7 7l.549.317a.5.5 0 1 1-.5.866L6.5 7.866V8.5a.5.5 0 0 1-1 0v-.634l-.549.317a.5.5 0 1 1-.5-.866L5 7l-.549-.317a.5.5 0 0 1 .5-.866l.549.317V5.5a.5.5 0 1 1 1 0m-2 4.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1m0 2h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1" />
                            </svg>
                        </span>
                        <h3 className="about__title">Diseño de Historiales Médicos</h3>
                        <p className="about__paragrah">Aprende a estructurar y diseñar historiales médicos digitales para una gestión eficiente de la información clínica.</p>
                    </article>

                    <article className="about__icons">
                        <span className="about__icon" style={{ color: "#0facbf" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07" />
                            </svg>
                        </span>
                        <h3 className="about__title">Implementación de Seguimientos</h3>
                        <p className="about__paragrah">Descubre cómo realizar seguimientos médicos en tiempo real para una atención médica más personalizada y efectiva.</p>
                    </article>

                    <article className="about__icons">
                        <span className="about__icon" style={{ color: "#0facbf" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
                            </svg>
                        </span>
                        <h3 className="about__title">Integración de Telemedicina</h3>
                        <p className="about__paragrah">Explora la integración de funcionalidades de telemedicina para consultas a distancia y acceso continuo a la atención médica.</p>
                    </article>
                </div>
            </section>

            <section className="knowledge">
                <div className="knowledge__container container">
                    <div className="knowledege__texts">
                        <h2 className="subtitle">Revolutionizando la Atención Médica con Mediconect</h2>
                        <p className="knowledge__paragraph">Experimenta una atención médica más personalizada y eficiente con Mediconect. Aprovechamos la potencia de la inteligencia artificial y la personalización a través de CSS para ofrecer experiencias únicas a profesionales de la salud y pacientes.</p>
                        <a href="#" className="cta">Descubre más <i className="bi bi-arrow-right"></i></a>
                    </div>

                    <figure className="knowledge__picture">
                        <img src={logo1} className="knowledge__img" alt="Imagen de muestra" />
                    </figure>
                </div>
            </section>

            <section className="price container">
                <h2 className="subtitle">Encuentra el Plan Ideal para Optimizar Mediconect</h2>

                <div className="price__table">
                    <div className="price__element">
                        <p className="price__name">Control Básico</p>
                        <h3 className="price__price">Gratis</h3>

                        <div className="price__items">
                            <p className="price__features">Acceso Básico a Mediconect</p>
                            <p className="price__features">Funcionalidad Limitada</p>
                            <p className="price__features">Soporte Comunitario</p>
                        </div>

                        <a href="#" className="price__cta">Explora Gratis</a>
                    </div>

                    <div className="price__element price__element--best">
                        <p className="price__name">Control Avanzado</p>
                        <h3 className="price__price">$30/mes</h3>

                        <div className="price__items">
                            <p className="price__features">Acceso Completo a Mediconect</p>
                            <p className="price__features">Funcionalidades Avanzadas</p>
                            <p className="price__features">Soporte Prioritario</p>
                        </div>

                        <a href="#" className="price__cta">Obtén el Plan</a>
                    </div>

                    <div className="price__element">
                        <p className="price__name">Control Premium</p>
                        <h3 className="price__price">$40/mes</h3>

                        <div className="price__items">
                            <p className="price__features">Acceso Premium a Mediconect</p>
                            <p className="price__features">Funcionalidades Premium</p>
                            <p className="price__features">Soporte 24/7</p>
                        </div>

                        <a href="#" className="price__cta">Obtén la Mejora</a>
                    </div>
                </div>
            </section>

            <section className="testimony">
                <div className="testimony__container container">
                    <img
                        src={img1}
                        className="testimony__arrow"
                        id="before"
                        onClick={handlePrevTestimony}
                        alt="Flecha izquierda"
                    />

                    {[1, 2, 3, 4].map((id) => (
                        <section
                            key={id}
                            className={`testimony__body ${currentTestimony === id ? 'testimony__body--show' : ''}`}
                            data-id={id}
                        >
                            <div className="testimony__texts">
                                <h2 className="subtitle">{`Mi nombre es ${testimonies[id - 1].name}, `}
                                    <span className="testimony__course">{testimonies[id - 1].course}</span>
                                </h2>
                                <p className="testimony__review">{testimonies[id - 1].review}</p>
                            </div>

                            <figure className="testimony__picture">
                                <img src={testimonies[id - 1].image} className="testimony__img" alt={`Foto de ${testimonies[id - 1].name}`} />
                            </figure>
                        </section>
                    ))}

                    <img
                        src={img2}
                        className="testimony__arrow"
                        id="next"
                        onClick={handleNextTestimony}
                        alt="Flecha derecha"
                    />
                </div>
            </section>

            <section className="questions container">
                <h2 className="subtitle">Preguntas Frecuentes sobre Mediconect</h2>
                <p className="questions__paragraph">Descubre cómo Mediconect revoluciona tu experiencia en el cuidado de la salud. Aquí tienes respuestas a preguntas comunes sobre nuestra plataforma.</p>

                <section className="questions__container ">
                    <article className={showicon == "1" ? "questions__padding questions__padding--add" : "questions__padding"}>
                        <div className="questions__answer">
                            <h3 className="questions__title">¿Cómo mejora Mediconect mi salud y bienestar?
                                <span className={showicon == "1" ? "questions__arrow questions__arrow--rotate" : "questions__arrow"}>
                                    <img src={img3} className="questions__img" alt="Flecha" onClick={() => setShow(showicon == "1" ? "0" : "1")} />
                                </span>
                            </h3>

                            <p className="questions__show">Mediconect se convierte en tu aliado personal para el cuidado de la salud. Ofrecemos herramientas avanzadas para gestionar enfermedades crónicas, conexiones directas con profesionales de la salud, información educativa personalizada y un control total sobre tu bienestar.</p>
                        </div>
                    </article>

                    <article className={showicon == "2" ? "questions__padding questions__padding--add" : "questions__padding"}>
                        <div className="questions__answer">
                            <h3 className="questions__title">¿Cuáles son las oportunidades de aprendizaje en Mediconect?
                                <span className={showicon == "2" ? "questions__arrow questions__arrow--rotate" : "questions__arrow"}>
                                    <img src={img3} className="questions__img" alt="Flecha" onClick={() => setShow(showicon == "2" ? "0" : "2")} />
                                </span>
                            </h3>

                            <p className="questions__show">Mediconect no solo te ayuda a gestionar tu salud, sino que también te ofrece oportunidades continuas de aprendizaje. Accede a recursos educativos, interactúa con otros pacientes crónicos, participa en programas de formación y mejora tus habilidades para llevar un estilo de vida más saludable y consciente.</p>
                        </div>
                    </article>

                    <article className={showicon == "3" ? "questions__padding questions__padding--add" : "questions__padding"}>
                        <div className="questions__answer">
                            <h3 className="questions__title">¿Cómo utiliza Mediconect tecnologías innovadoras?
                                <span className={showicon == "3" ? "questions__arrow questions__arrow--rotate" : "questions__arrow"}>
                                    <img src={img3} className="questions__img" alt="Flecha" onClick={() => setShow(showicon == "3" ? "0" : "3")} />
                                </span>
                            </h3>

                            <p className="questions__show">En Mediconect, incorporamos tecnologías innovadoras para diseñar una experiencia única. Utilizamos inteligencia artificial, análisis de datos avanzados y una interfaz amigable para proporcionar un entorno personalizado y fácil de usar, mejorando así tu experiencia en el cuidado de la salud.</p>
                        </div>
                    </article>
                </section>

                <section className="questions__offer">
                    <h2 className="subtitle">¿Listo para transformar tu atención médica con Mediconect?</h2>
                    <p className="questions__copy">Descubre cómo Mediconect va más allá de la atención médica tradicional. Únete hoy y experimenta una nueva era en el cuidado personalizado de la salud.</p>
                    <a href="https://medi-connect.flutterflow.app/" className="cta" download="">Descargar Mediconect</a>
                </section>
            </section>

        </main>
    );
};

export default Main;


const testimonies = [
    {
        name: 'Jordan Alexander',
        course: 'paciente crónico',
        review: 'En mi búsqueda de atención médica, encontré en Mediconect un aliado invaluable. La plataforma ha mejorado mi experiencia como paciente crónico al proporcionar herramientas y recursos para gestionar mi salud de manera efectiva.',
        image: pimg1,
    },
    {
        name: 'Alejandra Perez',
        course: 'paciente crónica',
        review: 'Mediconect ha sido fundamental en mi viaje como paciente crónica. La plataforma me brinda acceso a información relevante, me conecta con profesionales de la salud y me ayuda a gestionar mi condición de manera proactiva y educativa.',
        image: pimg2,
    },
    {
        name: 'Karen Arteaga',
        course: 'paciente crónica',
        review: 'La atención del paciente crónico nunca ha sido tan personalizada. Con Mediconect, he encontrado un entorno donde puedo aprender, conectarme con otros pacientes y recibir el apoyo necesario para gestionar mi salud de manera integral.',
        image: pimg3,
    },
    {
        name: 'Kevin Ramirez',
        course: 'paciente crónico',
        review: 'En Mediconect, encuentro herramientas que facilitan la gestión de mi condición como paciente crónico. La plataforma va más allá de la atención tradicional, proporcionando recursos valiosos que impactan positivamente en mi calidad de vida.',
        image: pimg4,
    },
];