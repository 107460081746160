import React from 'react';
import logo from '../img/estetoscopio.png'



const Header = (props) => {

  
  const estiloBefore = {
    '--backgroundImage-before': `url(${props.fondo})`
  };

  return (
    <header className="hero" style={estiloBefore} >
      <nav className="nav container"  >
        <div className="nav__logo">
          <h2 className="nav__title">App <span className="highlight">MEDIC</span><img  style={{transform:"translateY(12px)"}} src={logo} alt="Icono" />NNECT</h2>
        </div>

        <ul className={ props.show?  "nav__link nav__link--menu" : "nav__link nav__link--show" } >
          <li className="nav__items">
            <a onClick={()=>{
              props.setshow(true);
              props.setRuta("home");
              
              }} className="nav__links">Inicio</a>
          </li>
          <li className="nav__items">
            <a onClick={()=>{
              props.setshow(true);
              props.setRuta("about");
              
              }} className="nav__links">Acerca de</a>
          </li>
          <li className="nav__items">
            <a onClick={()=>{
              props.setshow(true);
              props.setRuta("contact");
              
              }} className="nav__links">Contacto</a>
          </li>
          <li className="nav__items">
            <a onClick={()=>{
              props.setshow(true);
              props.setRuta("blog");
              
              }} className="nav__links">Blog</a>
          </li>
        </ul>
      </nav>

      <section className="hero__container container">
        <h1 className="hero__title"><span className="highlight">MEDIC</span><img style={{transform:"translateY(5px) ", height:"80%", zIndex:"-1"}} src={logo} alt="Icono" />NNECT</h1>
        <p className="hero__paragraph">Tu solución para el control médico en tiempo real. Con Mediconect, obtén acceso inmediato
          a la información médica que necesitas, donde y cuando la necesitas.</p>
        <a onClick={()=>props.setRuta("about")}  className="cta">Descubre más</a>
      </section>
    </header>
  );
};

export default Header;
