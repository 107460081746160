// Contact.js
import React from 'react';

const Contact = () => {
  return (
    <main className="contact__main container">
      <section className="contact__section">
        <h2 className="section__title">Información de Contacto</h2>
        <p className="section__paragraph">No dudes en comunicarte con nosotros para cualquier pregunta o consulta. Estamos disponibles para ayudarte.</p>

        <div className="contact__info">
          <div className="info__item">
            <i className="bi bi-geo-alt"></i>
            <p>Dirección: <span className="highlight">Tu Dirección, Ciudad, País</span></p>
          </div>
          <div className="info__item">
            <i className="bi bi-envelope"></i>
            <p>Email: <span className="highlight">info@mediconect.com</span></p>
          </div>
          <div className="info__item">
            <i className="bi bi-phone"></i>
            <p>Teléfono: <span className="highlight">(+XX) XXXX-XXXX</span></p>
          </div>
        </div>
      </section>

      <section className="contact__form">
        <h2 className="section__title">Formulario de Contacto</h2>
        <p className="section__paragraph">Completa el formulario a continuación y nos pondremos en contacto contigo lo antes posible.</p>

        <form action="#" method="post" className="form">
          <label htmlFor="nombre" className="form__label">Nombre:</label>
          <input type="text" id="nombre" name="nombre" className="form__input" required />

          <label htmlFor="email" className="form__label">Email:</label>
          <input type="email" id="email" name="email" className="form__input" required />

          <label htmlFor="mensaje" className="form__label">Mensaje:</label>
          <textarea id="mensaje" name="mensaje" className="form__textarea" required></textarea>

          <input type="submit" value="Enviar Mensaje" className="form__submit" />
        </form>
      </section>
    </main>
  );
};

export default Contact;
