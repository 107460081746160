import React from 'react';
import logo1 from'../images/facebook.svg';
import logo2 from'../images/twitter.svg';
import logo3 from'../images/youtube.svg';


const Footer = (props) => {
  return (
    <footer className="footer">
      <section className="footer__container container">
        <nav className="nav nav--footer">
          <h2 className="footer__title">Mediconect</h2>

          <ul className="nav__link nav__link--footer">
            <li className="nav__items">
              <a onClick={()=>props.setRuta("home")} className="nav__links">Inicio</a>
            </li>
            <li className="nav__items">
              <a onClick={()=>props.setRuta("about")} className="nav__links">Acerca de Mediconect</a>
            </li>
            <li className="nav__items">
              <a onClick={()=>props.setRuta("contact")} className="nav__links">Contacto</a>
            </li>
            <li className="nav__items">
              <a onClick={()=>props.setRuta("blog")} className="nav__links">Blog de Salud</a>
            </li>
          </ul>
        </nav>

        <form className="footer__form" action="https://formspree.io/f/mknkkrkj" method="POST">
          <h2 className="footer__newsletter">Suscríbete a Mediconect</h2>
          <div className="footer__inputs">
            <input type="email" placeholder="Email:" className="footer__input" name="_replyto" />
            <input type="submit" value="Regístrate" className="footer__submit" />
          </div>
        </form>
      </section>

      <section className="footer__copy container">
        <div className="footer__social">
          <a href="#" className="footer__icons"><img src={logo1} className="footer__img" alt="Facebook" /></a>
          <a href="#" className="footer__icons"><img src={logo2} className="footer__img" alt="Twitter" /></a>
          <a href="#" className="footer__icons"><img src={logo3} className="footer__img" alt="YouTube" /></a>
        </div>

        <h3 className="footer__copyright">Derechos reservados © Mediconect</h3>
      </section>
    </footer>
  );
};

export default Footer;
